import { useEffect, useState } from "react";
import "./progressbar.scss";

const scrollRainbowStyle = (height = "3") => ({
  top: 0,
  zIndex: "99",
  background: `linear-gradient(90deg, #FF5252 0.71%, #F88E52 19.62%, #FCF296 38.51%, #77C68D 60.5%, #4A63A3 80.06%, #B67CE2 99.94%)`,
  height: `${height}px`,
  width: `100%`,
});

const scrollGrayStyle = (width, height = "3", duration = "1") => ({
  position: "absolute",
  top: 0,
  right: 0,
  zIndex: "99",
  background: `#7A7A7A`,
  height: `${height}px`,
  width: `calc(100% - ${width})`,
  transitionProperty: "width",
  transitionDuration: `${duration}s`,
  transitionTimingFunction: `ease-out`,
});

const ProgressBar = ({ height, duration }) => {
  const [width, setWidth] = useState("0px");

  // scroll listener
  useEffect(() => {
    window.addEventListener("scroll", Scrolling);
    return () => window.removeEventListener("scroll", Scrolling);
  });

  const Scrolling = () => {
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;
    const height =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;
    const scrolled = (winScroll / height) * 100;

    if (height > 0) {
      setWidth(`${scrolled}%`);
    } else {
      setWidth("0px");
    }
  };

  return (
    <div className="progressbar">
      <div style={scrollRainbowStyle(height)} />
      <div style={scrollGrayStyle(width, height, duration)} />
    </div>
  );
};

export default ProgressBar;
