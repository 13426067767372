import "./DetailPost.scss";
import { ShareGroup } from "../shareGroup/ShareGroup";
import moment from "moment"
import dompurify from "dompurify";

const DetailPost = (props) => {
  const { title, tags = [], date, content, page, id } = props;
  const sanitizer = dompurify.sanitize;
  return (
    <>
      <div className="detail-post__header">
        <div className="detail-post__header-title font-bold">{title}</div>
        <div className="detail-post__header-tags">
          {tags.map((item, index) =>
            item.tag_name ? <span key={index}>#{item.tag_name}</span> : ""
          )}
        </div>
        <div className="detail-post__header-date">{moment(date).format('YYYY-MM-DD')}</div>
      </div>

      <div className="detail-post__content">
        <div dangerouslySetInnerHTML={{ __html: sanitizer(content) }}></div>
      </div>

      <ShareGroup title={title} content={content} page={page} id={id} />
    </>
  );
};

export default DetailPost;
