import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import ProgressBar from "../progressbar/ProgressBar";
import "./Header.scss";
import { useLocation } from "react-router-dom";

const Header = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isMenuOpen = useSelector((state) => state.menuOpen);
  const location = useLocation();
  const path = location.pathname;

  // 페이지 이동 + 메뉴 닫기.
  const pageMove = (url) => {
    navigate(url);
    dispatch({ type: "menuClose" });
  };

  // 햄버거바 클릭, 메뉴 상태 관리
  const handleMenuOpen = () => {
    isMenuOpen
      ? dispatch({ type: "menuClose" })
      : dispatch({ type: "menuOpen" });
  };

  const checkWhiteSection = () => {
    const whiteSection = document.querySelectorAll(".white-logo-section");
    const whiteLogo = document.querySelector(".header__nav-brand__white");
    const colorLogo = document.querySelector(".header__nav-brand__color");
    const whiteMenuBtn = document.querySelector(".header__nav-menu__white");
    const colorMenuBtn = document.querySelector(".header__nav-menu__color");
    const header = document.querySelector(".header__nav");
    const logoHeight = document
      .querySelector(".header__nav-brand")
      .getBoundingClientRect().height;
    const progressbarHeight = document
      .querySelector(".progressbar")
      .getBoundingClientRect().height;
    const offsetPaddingTop =
      Number(getComputedStyle(header).paddingTop.replace("px", "")) +
      progressbarHeight;

    let workSectionIdx;

    whiteSection.forEach((s, sIdx) => {
      if(whiteLogo === null || colorLogo === null || whiteMenuBtn === null || colorMenuBtn === null) return
      // 1) priority
      whiteLogo.style.zIndex = 9;
      colorLogo.style.zIndex = 8;
      whiteMenuBtn.style.zIndex = 9;
      colorMenuBtn.style.zIndex = 8;

      // 2) white height ratio (후순위인 로고의 height 을 조정한다.)
      colorLogo.style.height = 100 + "%";
      colorMenuBtn.style.height = 100 + "%";

      if (
        s.getBoundingClientRect().top < logoHeight + offsetPaddingTop &&
        s.getBoundingClientRect().top >=
        offsetPaddingTop - s.getBoundingClientRect().height
      ) {
        workSectionIdx = sIdx;
        // color + white
        if (s.getBoundingClientRect().top > offsetPaddingTop) {
          // 1) priority
          whiteLogo.style.zIndex = 8;
          colorLogo.style.zIndex = 9;
          whiteMenuBtn.style.zIndex = 8;
          colorMenuBtn.style.zIndex = 9;

          // 2) white height ratio (후순위인 로고의 height 을 조정한다.)
          colorLogo.style.height =
            ((s.getBoundingClientRect().top - offsetPaddingTop) / logoHeight) *
            100 +
            "%";
          colorMenuBtn.style.height =
            ((s.getBoundingClientRect().top - offsetPaddingTop) / logoHeight) *
            100 +
            "%";
        }

        // 100% white
        else if (
          s.getBoundingClientRect().top <= offsetPaddingTop &&
          s.getBoundingClientRect().top >=
          offsetPaddingTop + logoHeight - s.getBoundingClientRect().height
        ) {
          // 1) priority -> no change
          whiteLogo.style.zIndex = 9;
          colorLogo.style.zIndex = 8;

          whiteMenuBtn.style.zIndex = 9;
          colorMenuBtn.style.zIndex = 8;

          // 2) white height ratio (후순위인 로고의 height 을 조정한다.)
          whiteLogo.style.height = 100 + "%";
          whiteMenuBtn.style.height = 100 + "%";
        }

        // white + color
        else if (
          s.getBoundingClientRect().top <
          offsetPaddingTop + logoHeight - s.getBoundingClientRect().height
        ) {
          // 1) priority -> no change
          whiteLogo.style.zIndex = 9;
          colorLogo.style.zIndex = 8;
          whiteMenuBtn.style.zIndex = 9;
          colorMenuBtn.style.zIndex = 8;

          // 2) white height ratio (후순위인 로고의 height 을 조정한다.)
          whiteLogo.style.height =
            ((s.getBoundingClientRect().bottom - offsetPaddingTop) /
              logoHeight) *
            100 +
            "%";
          whiteMenuBtn.style.height =
            ((s.getBoundingClientRect().bottom - offsetPaddingTop) /
              logoHeight) *
            100 +
            "%";
        }
      } else if (workSectionIdx === undefined) {
        whiteLogo.style.zIndex = 8;
        colorLogo.style.zIndex = 9;
        whiteLogo.style.height = 100 + "%";
        colorLogo.style.height = 100 + "%";

        whiteMenuBtn.style.zIndex = 8;
        colorMenuBtn.style.zIndex = 9;
        whiteMenuBtn.style.height = 100 + "%";
        colorMenuBtn.style.height = 100 + "%";
      }
    });

    if (path === "/" && window.scrollY === 0) {
      whiteLogo.style.zIndex = 8;
      colorLogo.style.zIndex = 9;

      whiteMenuBtn.style.zIndex = 8;
      colorMenuBtn.style.zIndex = 9;
    }
  };

  useEffect(() => {
    checkWhiteSection();
  }, [path]);

  window.addEventListener("scroll", () => {
    checkWhiteSection();
  });

  return (
    <header className="header">
      <ProgressBar height="7" duration="0" />

      <div className="header__nav">
        <div
          className="header__nav-brand"
          onClick={() => {
            pageMove("/");
          }}>
          <div className="header__nav-brand__color" />
          <div className="header__nav-brand__white" />
        </div>

        <div className="header__nav-menu">
          {isMenuOpen ? (
            <></>
          ) : (
            <button onClick={handleMenuOpen}>
              <div className="header__nav-menu__color" />
              <div className="header__nav-menu__white" />
            </button>
          )}
        </div>
      </div>
    </header>
  );
};
export default Header;
