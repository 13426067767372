import { withCookies } from "react-cookie";
import { Route, Routes } from "react-router-dom";
import Posts from "../pages/news/posts";
import Press from "../pages/news/press";
import PostDetail from "../pages/news/postDetail";
import PressDetail from "../pages/news/pressDetail";
import Error from "../pages/error/Error";

const NewsRouter = () => {
  return (
    <Routes>
      {/* <Route path="/" element={<News />} /> */}
      {/*<Route path="/notice" element={<Notice />} />*/}
      {/*<Route path="/notice/:id" element={<NoticeDetail />} />*/}
      <Route path="/post" element={<Posts />} />
      <Route path="/post/:id" element={<PostDetail />} />
      <Route path="/press" element={<Press />} />
      <Route path="/press/:id" element={<PressDetail />} />
      <Route path="/*" element={<Error />} />
    </Routes>
  );
};
export default withCookies(NewsRouter);
