import {useState} from "react";
import Alert from "../alert/Alert";

export const LinkShare = (props) => {
    const [open, setOpen] = useState(false);
    const [text, setText] = useState(null);

    // alert handling
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    // clipboard 에 복사하기
    const handleClick = (text) => {
        // 흐름 1.
        if (navigator.clipboard) {
            // (IE는 사용 못하고, 크롬은 66버전 이상일때 사용 가능합니다.)
            navigator.clipboard
                .writeText(text)
                .then(() => {
                    setText(text);
                    handleClickOpen();
                })
                .catch(() => {
                    setText("복사를 다시 시도해주세요.");
                    handleClickOpen();
                });
        } else {
            // 흐름 2.
            if (!document.queryCommandSupported("copy")) {
                setText("복사하기가 지원되지 않는 브라우저입니다.");
                handleClickOpen();
                return;
            }

            // 흐름 3.
            const textarea = document.createElement("textarea");
            textarea.value = text;
            textarea.style.top = 0;
            textarea.style.left = 0;
            textarea.style.position = "fixed";

            // 흐름 4.
            document.body.appendChild(textarea);
            // focus() -> 사파리 브라우저 서포팅
            textarea.focus();
            // select() -> 사용자가 입력한 내용을 영역을 설정할 때 필요
            textarea.select();
            // 흐름 5.
            document.execCommand("copy");
            // 흐름 6.
            document.body.removeChild(textarea);

            setText(text);
            handleClickOpen();
        }
    };

    return (
        <>
            <button onClick={() => handleClick(window.location.href)}>
                {props.children}
            </button>

            <Alert text={text} open={open} handleClose={handleClose}/>
        </>
    );
};

export const KakaoShare = (props) => {
    const shareKakaoLink = () => {
        const linkPath = props.url;
        /* eslint-disable */
        Kakao.Share.createDefaultButton({
            container: "#kakaotalk-sharing-btn",
            objectType: "text",
            text: props?.content,
            // imageUrl: '',
            link: {
                // [내 애플리케이션] > [플랫폼] 에서 등록한 사이트 도메인과 일치해야 함
                mobileWebUrl: linkPath,
                webUrl: linkPath,
            },
        });
    };

    return (
        <button
            id="kakaotalk-sharing-btn"
            type="button"
            onClick={() => shareKakaoLink()}>
            {props.children}
        </button>
    );
};
