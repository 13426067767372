import { useState } from "react";
import Paging, { usePagination } from "../../components/paging/Paging";
import PostTemplate from "../../components/template/PostTemplate";

export const SearchPost = ({ data, count, searchText, handlePage }) => {
  const [viewItem] = useState(10);
  const [page, setPage] = useState(1);
  const pageCount = Math.ceil(count / viewItem);
  const _searchList = usePagination(data, viewItem);

  const handleChange = (e, p) => {
    handlePage(p);
    setPage(p);
    _searchList.jump(p);
    window.scrollTo(0, 0);
  };

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-4">
      {_searchList.currentData()?.map((item) => (
        <PostTemplate
          key={item.no}
          id={item.no}
          title={item.title}
          content={item.subTitle}
          tags={item.tags}
          date={item.date}
          src={`/post_thumbnail/${item.image}`}
          searchText={searchText}
        />
      ))}

      <div className="search__footer flex justify-center">
        {data.length > 0 ? (
          <Paging
            page={page}
            count={pageCount}
            handleChange={(e, p) => handleChange(e, p)}
          />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};
