import { withCookies } from "react-cookie";
import { Route, Routes } from "react-router-dom";
import Project from "../pages/project";
import Error from "../pages/error/Error";

const ProjectRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<Project />} />
      <Route path="/*" element={<Error />} />
    </Routes>
  );
};
export default withCookies(ProjectRouter);
