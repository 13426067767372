import { useEffect } from "react";
import { Box } from "@mui/material";
import { Outlet, useLocation } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";
import Menu from "../menu/Menu";
import MoveTopBtn from "../MoveTopBtn/MoveTopBtn";

const Layout = (props) => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
      }}>
      <Header />
      <Menu />

      <div style={{ minHeight: 100 + "vh" }}>{props.children}</div>

      <MoveTopBtn />
      <Outlet />
      <Footer />
    </Box>
  );
};

export default Layout;
