import parse from "html-react-parser";

/**
 * 1. 글의 서두가 이미지 태그일 경우 삭제하는 기능
 * 2. 글의 길이를 축소하는 기능 ( summary 값 )
 * 3. 해당 단어에  span 태그와 __highlight 클래스를 주어서 css 상에서 style 을 바꿔줌. 검색 단어 앞뒤를 잘라놓고 태그를 다 붙인 다음에 다시 앞뒤를 이어붙이는 구조.
 */
const shortenAndHighlightingSentence = (sentence, highlight, summary) => {
  if (sentence.includes("<img")) {
    let imgStart = sentence.indexOf("<p><img");
    let imgEnd = sentence.indexOf("></p>", sentence.indexOf("<img"));

    let beforeElem = sentence.substring(0, imgStart);
    let afterElem = sentence.substring(imgEnd + 5, sentence.length);

    sentence = beforeElem + afterElem;
  }

  sentence = sentence.replace("<p>", "");

  if (summary && sentence.length > summary) {
    sentence = sentence.slice(0, summary) + "...";
  }

  if (highlight) {
    let highlightLowerCase = highlight.toString().toLowerCase();
    const position = sentence.toLowerCase().indexOf(highlightLowerCase);
    let output = "";

    if (position !== -1) {
      output = [
        sentence.slice(0, position),
        "<span class='__highlight'>",
        sentence.slice(position, position + highlight.length),
        "</span>",
        sentence.slice(position + highlight.length),
      ].join("");

      return parse(output);
    } else return sentence;
  } else return sentence;
};

export { shortenAndHighlightingSentence };
