import { useNavigate } from "react-router-dom";
import {
  Button,
  styled,
  Tooltip,
  tooltipClasses,
  TooltipProps,
} from "@mui/material";
import { ReactComponent as nextButton } from "../../assets/img/icon/arrow_next.svg";
import { ReactComponent as prevButton } from "../../assets/img/icon/arrow_prev.svg";
import "./DetailPagination.scss";

const DetailPagination = ({ page, pageData }) => {
  const { prev_no, prev_title, next_no, next_title } = pageData;
  const navigate = useNavigate();
  const WORDLIMITLENGTH = 10;

  const movePage = (no) => {
    navigate(`/news/${page}/${no}`);
  };

  // 제목 글자 제한
  const titleShorten = (text) => {
    if (text.length > 1) {
      return text.slice(0, WORDLIMITLENGTH) + "..";
    }
  };

  // tooltip
  const NoMaxWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: "none",
    },
  });

  return (
    <div className="detail-pagination grid grid-cols-12 mx-auto">
      <div className="detail-pagination__move-btn-container prev col-span-5 flex">
        <div className="flex">
          {prev_no > 0 ? (
            <Button
              startIcon={
                <span className="detail-pagination__move-btn-arrow">
                  <img className="icon-arrow-left" src={prevButton} alt="" />
                </span>
              }
              onClick={() => movePage(prev_no)}
              className="detail-pagination__move-btn">
              이전 글
            </Button>
          ) : (
            <Button
              disabled
              startIcon={
                <span className="detail-pagination__move-btn-arrow">
                  <img className="icon-arrow-left" src={prevButton} alt="" />
                </span>
              }
              className="detail-pagination__move-btn">
              처음 글
            </Button>
          )}
          <NoMaxWidthTooltip title={prev_title}>
            <div className="detail-pagination__tooltip">
              {titleShorten(prev_title)}
            </div>
          </NoMaxWidthTooltip>
        </div>
      </div>

      <div
        className="detail-pagination__list-btn-container col-span-2"
        onClick={() => navigate(`/news/${page}`)}>
        <Button>목록</Button>
      </div>

      <div className="detail-pagination__move-btn-container next col-span-5 flex justify-end">
        <div className="flex">
          <NoMaxWidthTooltip title={next_title}>
            <div className="detail-pagination__tooltip">
              {titleShorten(next_title)}
            </div>
          </NoMaxWidthTooltip>
          {next_no > 0 ? (
            <Button
              endIcon={
                <span className="detail-pagination__move-btn-arrow">
                  <img className="icon-arrow-right" src={nextButton} alt="" />
                </span>
              }
              onClick={() => movePage(next_no)}
              className="detail-pagination__move-btn">
              다음 글
            </Button>
          ) : (
            <Button
              disabled
              endIcon={
                <span className="detail-pagination__move-btn-arrow">
                  <img className="icon-arrow-right" src={nextButton} alt="" />
                </span>
              }
              className="detail-pagination__move-btn">
              마지막 글
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default DetailPagination;
