import { useState } from "react";
import toTopBtn from "../../assets/img/toTopBtn.png";
import toTopBtnHover from "../../assets/img/toTopBtn_hover.png";
import "./MoveTopBtn.scss";

const MoveTopBtn = () => {
  const [isMouseHover, setIsMouseHover] = useState(false);
  const [visible, setVisible] = useState(false);

  window.addEventListener("scroll", () => {
    const browserHeight = window.innerHeight;

    if (window.scrollY > browserHeight) {
      setVisible(true);
    } else {
      setVisible(false);
    }
  });

  const moveTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <div className="move-top-btn">
      <button
        onClick={() => moveTop()}
        onMouseOver={() => setIsMouseHover(true)}
        onMouseLeave={() => setIsMouseHover(false)}>
        {visible ? (
          isMouseHover ? (
            <img src={toTopBtnHover} alt={""} />
          ) : (
            <img src={toTopBtn} alt={""} />
          )
        ) : (
          <></>
        )}
      </button>
    </div>
  );
};

export default MoveTopBtn;
