import "./Timeline.scss";
import moment from "moment";

const Timeline = ({ data }) => {
  return (
    <div className="timeline">
      {data.reverse().map((timeline, timeline_idx) => {
        return (
          <div className="timeline-item" key={timeline_idx}>
            <div className="timeline-item__year">
              <h3>{timeline.year}'s</h3>
            </div>
            <ul className="timeline-item__text">
              {timeline.history.map((item, item_idx) => {
                const contents = item.title.split("#^");

                return (
                  <li
                    className="fadeInUp animated"
                    data-animation="fadeInUp"
                    style={{ cursor: "default" }}
                    key={item_idx}>
                    <h4>
                      {moment(`${timeline.year}${item.date}`).format(
                        "MM월 DD일"
                      )}
                    </h4>
                    <p>
                      {contents.map((content, index) => (
                        <div>{content}</div>
                      ))}
                    </p>
                  </li>
                );
              })}
            </ul>
          </div>
        );
      })}
    </div>
  );
};

export default Timeline;
