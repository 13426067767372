import { useEffect, useState } from "react";
import { useParams } from "react-router";
import Banner from "../../components/banner/Banner";
import DetailPagination from "../../components/detailPage/DetailPagination";
import DetailPost from "../../components/detailPage/DetailPost";
import "./detail-page.scss";
import { postAPI } from "../../common/api/waoBackApi";
import GoList from "../../components/goList/GoList";
import { Helmet } from "react-helmet-async";

const PostDetail = () => {
  let { id } = useParams();
  id = Number(id);
  const [apiData, setApiData] = useState([]);
  const [paginationData, setPaginationData] = useState(null);

  const getApiData = () => {
    postAPI(`/contents/post/detail?no=${id}`)
      .then(({ data }) => {
        setApiData(data.data);
        setPaginationData(makePageMoveData({ ...data.data }));
      })
      .catch((err) => {
        // logger.error(`/contents/post/detail api error : ${err}`);
      });
  };

  const makePageMoveData = ({ prev_no, prev_title, next_no, next_title }) => ({
    prev_no,
    prev_title,
    next_no,
    next_title,
  });

  useEffect(() => {
    getApiData();
  }, [, id]);

  return (
    <div className="detail-page">
      <Helmet>
        <title>포스트 | 위아원</title>
        <meta name="description" content={apiData.contents} />
        <meta property="og:title" content={apiData.title} />
        <meta property="og:site_name" content={"포스트 | 위아원"} />
        <meta property="og:description" content={apiData.contents} />
      </Helmet>
      <Banner
        category="소식"
        menu="포스트"
        desc="위아원의 다양한 소식을 전합니다."
        page="post"
      />

      <div className="detail-page-container container mx-auto">
        <GoList page="post" />

        <div className="detail-page__content max-w-3xl mx-auto px-24">
          <DetailPost
            title={apiData.title}
            content={apiData.contents}
            tags={apiData.tags}
            date={apiData.date}
            id={apiData.no}
            page="post"
          />
        </div>
        {paginationData !== null ? (
          <DetailPagination page="post" pageData={paginationData} />
        ) : (
          ""
        )}
      </div>
    </div>
  );
};
export default PostDetail;
