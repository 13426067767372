import { shortenAndHighlightingSentence } from "./utils";
import "./ListTemplate.scss";
import moment from "moment";
const ListTemplate = ({
  title,
  content,
  tags = [],
  date,
  menu,
  wordLimitLength = 100,
  searchText,
  thumbnail,
}) => {
  return (
    <div className="list-template">
      {menu ? <div className="list-template__category">{menu}</div> : <></>}
      <div
        className="list-template__title font-bold"
        onClick={() =>
          window.open(thumbnail, "_blank", "noopener, noreferrer")
        }>
        {shortenAndHighlightingSentence(title, searchText)}
      </div>
      <div className="list-template__content">
        {shortenAndHighlightingSentence(content, searchText, wordLimitLength)}
      </div>
      <div className="list-template__tags">
        {tags?.map(({ tag_name }, index) => (
          <span key={index}>
            #{shortenAndHighlightingSentence(tag_name, searchText)}
          </span>
        ))}
      </div>
      <div className="list-template__date">
        {moment(date).format("YYYY-MM-DD")}
      </div>
    </div>
  );
};

export default ListTemplate;
