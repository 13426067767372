import { useEffect, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  IconButton,
} from "@mui/material";
import { MdArrowBackIos, MdArrowForwardIos, MdClose } from "react-icons/md";
import "./GalleryModal.scss";
import { postAPI } from "../../common/api/waoBackApi";

export const GalleryModal = ({ props, handleOpen, params }) => {
  const { year = "", project = "", search = "" } = params;
  const { no } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [selectedImage, setSelectedImage] = useState({
    no: "",
    image: "",
    tags: [],
    prev_no: "",
    next_no: "",
  });

  const fetchImage = (no) => {
    setIsLoading(true);
    if (no > 0) {
      postAPI(
        `/contents/gallery/detail?no=${no}&year=${year}&project=${project}&search=${search}`
      )
        .then(({ data }) => {
          setSelectedImage(data.data);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
        });
    }
  };
  useEffect(() => {
    fetchImage(no);
  }, [, no]);

  return (
    <Dialog
      className="gallery-list__modal"
      fullWidth={true}
      open={true}
      onClose={() => handleOpen(false)}>
      <DialogActions className="gallery-list__modal-action">
        <IconButton onClick={() => handleOpen(false)}>
          {isLoading ? <></> : <MdClose />}
        </IconButton>
      </DialogActions>
      <DialogContent className="gallery-list__modal-body">
        <div className="gallery-list__modal-img-cover flex justify-center">
          {isLoading ? (
            <></>
          ) : (
            <img
              className="gallery-list__modal-img"
              src={`https://weareone-youth.org/gallery/${selectedImage.image}`}
              alt=""
            />
          )}
        </div>
        <DialogContentText
          className="gallery-list__modal-tags"
          sx={{ marginTop: "12px" }}>
          {selectedImage.tags.map((item, index) =>
            item.tag_name ? <span key={index}>#{item.tag_name}</span> : ""
          )}
        </DialogContentText>
      </DialogContent>

      <DialogActions className="gallery-list__modal-direction prev">
        <IconButton
          disabled={selectedImage.prev_no < 0}
          sx={selectedImage.prev_no < 0 ? { filter: "brightness(0.4)" } : {}}
          onClick={() => fetchImage(selectedImage.prev_no)}>
          <MdArrowBackIos />
        </IconButton>
      </DialogActions>
      <DialogActions className="gallery-list__modal-direction next">
        <IconButton
          disabled={selectedImage.next_no < 0}
          sx={selectedImage.next_no < 0 ? { filter: "brightness(0.4)" } : {}}
          onClick={() => fetchImage(selectedImage.next_no)}>
          <MdArrowForwardIos />
        </IconButton>
      </DialogActions>
    </Dialog>
  );
};
