import { useEffect, useState } from "react";
import Isotope from "isotope-layout";
import imagesLoaded from "imagesloaded";
import { GalleryModal } from "../../components/template/GalleryModal";
import clsx from "clsx";

export const SearchGallery = ({ data, count, handlePage, params }) => {
  const [page, setPage] = useState(1);
  const [isModalOpen, setModalOpen] = useState(false);
  const [modalProps, setModalProps] = useState();
  const totalPage = count === 0 ? 1 : Math.floor(count / 10) + 1;
  const handleModalOpen = (o) => setModalOpen(o);

  const clickArticle = (item) => {
    setModalOpen(true);
    setModalProps(item);
  };

  // Isotope effect
  useEffect(() => {
    const grid = document.querySelector(".search-gallery-list__isotope");

    const iso = new Isotope(grid, {
      itemSelector: "article",
      layoutMode: "masonry",
    });

    imagesLoaded(".search-gallery-list__isotope", () => {
      iso.layout(); // 'progress'
    });
  });

  useEffect(() => {
    handlePage(1);
    setPage(1);
  }, [params.search]);
  return (
    <div className="search-gallery-list">
      <section className="search-gallery-list__isotope">
        {data.map((item, index) => (
          <article
            key={index}
            className={"search-gallery-list__isotope-article "}
            onClick={() => clickArticle(item)}>
            <img
              className="search-gallery-list__isotope-img"
              key={index}
              src={`/gallery_thumbnail/${item.image}`}
            />
          </article>
        ))}
      </section>
      {data.length > 0 ? (
        <div
          className={clsx("search-gallery-list__loader", {
            "not-end": page < totalPage,
          })}>
          <button
            style={{ zIndex: 999 }}
            onClick={() => {
              if (page < totalPage) {
                setPage(page + 1);
                handlePage(page + 1);
              }
            }}
            disabled={page >= totalPage}>
            <p>더보기</p>
            <p>{`( ${page} / ${totalPage} )`}</p>
          </button>
        </div>
      ) : (
        <></>
      )}

      {isModalOpen ? (
        <GalleryModal
          props={modalProps}
          handleOpen={handleModalOpen}
          params={params}
        />
      ) : (
        <></>
      )}
    </div>
  );
};
