import { useState } from "react";
import { IconButton } from "@mui/material";
import { FAMILYSITE, SNS_LINK } from "./data";
import IconInstargram from "../../assets/img/icon/IconInstargram.svg";
import IconInstargram_hover from "../../assets/img/icon/IconInstargram_hover.svg";
import IconYoutube from "../../assets/img/icon/IconYoutube.svg";
import IconYoutube_hover from "../../assets/img/icon/IconYoutube_hover.svg";
import footer_logo from "../../assets/img/logo/footer_logo.png";
import "./Footer.scss";

export default function Footer() {
  const [instagramHover, setInstagramHover] = useState(false);
  const [youtubeHover, setYoutubeHover] = useState(false);
  const [activeClass, setActiveClass] = useState("")

  // family site 클릭시 해당 사이트 새탭으로 열기
  const siteOpenWithNewTab = (link) => {
    window.open(link, "_blank", "noreferrer");
    setActiveClass('')
  };
  const handleSelect = () => {
    setActiveClass('active')
  }

  const clickMenu = (item) => {
    if (item) {
      siteOpenWithNewTab(item.link)
    }
    setActiveClass('')
  }

  return (
    <div className="footer">
      <div className="footer-wrapper grid grid-cols-12">
        <div className="footer__top col-span-6 md:col-span-7">
          <div className="footer__top__logo-cover font-bold">
            <img className="footer__top__logo" alt="logo" src={footer_logo} />
          </div>

          <div className="footer__bottom-copyright">
            Copyright ⓒ shincheonji. <br />
            All Contents Are Property Of Shincheonji. All Rights Reserved.
          </div>
        </div>

        <div className="footer__bottom col-span-6 md:col-span-5">
          <div className="footer__bottom-wrapper grid grid-cols-12 gap-2">
            <div className="footer__bottom__sns md:col-span-4 col-span-12">
              <IconButton
                aria-label="instagram"
                onClick={() => siteOpenWithNewTab(SNS_LINK.instagram)}
                onMouseOver={() => setInstagramHover(true)}
                onMouseOut={() => setInstagramHover(false)}>
                {instagramHover ? (
                  <img src={IconInstargram_hover} />
                ) : (
                  <img src={IconInstargram} />
                )}
              </IconButton>
              <IconButton
                aria-label="youtube"
                onClick={() => siteOpenWithNewTab(SNS_LINK.youtube)}
                onMouseOver={() => setYoutubeHover(true)}
                onMouseOut={() => setYoutubeHover(false)}>
                {youtubeHover ? (
                  <img src={IconYoutube_hover} />
                ) : (
                  <img src={IconYoutube} />
                )}
              </IconButton>
            </div>
            <div className="footer__bottom__family md:col-span-8 col-span-12 ">


              <div className={`footer__bottom__family-form ${activeClass}`} onMouseLeave={() => setActiveClass('')}>
                <button className="footer__bottom__family-select-label" onClick={() => handleSelect()} >Family site</button>
                <ul className="footer__bottom__family-menu-items">
                  {
                    FAMILYSITE.map((item, idx) =>
                      <li key={idx} className="footer__bottom__family-menu-item" onClick={() => clickMenu(item)}><a>{item.name}</a></li>
                    )
                  }
                  <li className="footer__bottom__family-menu-item" onClick={() => clickMenu()}>Family site</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
