const initState = {
  search: "",
};

export default function doSearch(state = initState, action) {
  if (action.type === "SEARCH") {
    state.search = action.payload.text;
    return state;
  } else return state;
}

