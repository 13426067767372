import { withCookies } from "react-cookie";
import { Route, Routes } from "react-router-dom";
import About from "../pages/about";
import Error from "../pages/error/Error";

const AboutRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<About />} />
      <Route path="/*" element={<Error />} />
    </Routes>
  );
};
export default withCookies(AboutRouter);
