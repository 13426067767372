import { FacebookShareButton, TwitterShareButton } from "react-share";
import { KakaoShare, LinkShare } from "./SocialShareComp";
import "./ShareGroup.scss";
import { ReactComponent as FacebookIcon } from "../../assets/img/icon/sns_facebook.svg";
import { ReactComponent as KakaoIcon } from "../../assets/img/icon/sns_kakao.svg";
import { ReactComponent as LinkIcon } from "../../assets/img/icon/sns_link.svg";
import { ReactComponent as TwitterIcon } from "../../assets/img/icon/sns_twitter.svg";
import MetaTag from "./MetaTag";
import { Helmet } from "react-helmet-async";
// TODO: facebook, twitter share 기능 구현해야 함. meta 데이터 설정 필요함.
export const ShareGroup = ({
  title = "",
  content = "",
  page,
  id,
  imageUrl = "",
}) => {
  const regexTag = (text) => {
    return text
      ?.replace(/(<([^>]+)>)/gi, "")
      .replace(/&quot;/g, "")
      .replace(/\"n/, "")
      .replace(/&amp;/g, "")
      .replace(/&nbsp;/g, " ");
  };

  return (
    <div className="share-group flex justify-center">
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={regexTag(content)} />
        <meta property="og:title" content={title} />
        <meta property="og:site_name" content={title} />
        <meta
          property="og:description"
          content={regexTag(content)?.slice(0, 100)}
        />
        <meta property="og:url" content={window.location.href} />
        <meta name="twitter:title" content={title} />
        <meta
          name="twitter:description"
          content={regexTag(content)?.slice(0, 100)}
        />
        <meta name="twitter:image:src" content={imageUrl} />
        <meta name="twitter:url" content={window.location.href} />
      </Helmet>
      <MetaTag props={{ title, content, page, id, imageUrl }} />
      <KakaoShare
        url={`${process.env.REACT_APP_HOST}news/${page}/${id}`}
        content={regexTag(content)}>
        <KakaoIcon className="kakao" />
      </KakaoShare>

      <FacebookShareButton
        url={`${process.env.REACT_APP_HOST}news/${page}/${id}`}>
        <FacebookIcon className="facebook" />
      </FacebookShareButton>

      <TwitterShareButton
        url={`${process.env.REACT_APP_HOST}news/${page}/${id}`}
        title={title}>
        <TwitterIcon className="twitter" />
      </TwitterShareButton>

      <LinkShare>
        <LinkIcon className="link" />
      </LinkShare>
    </div>
  );
};
