import "./Banner.scss";
import { useEffect, useState } from "react";

const Banner = ({ category, menu, desc, page }) => {
  let size = "lg";
  const [imageSrc, setImageSrc] = useState(`/banner/${page}_${size}.png`);

  const BannerImageStyle = (src) => ({
    backgroundImage: `url("${src}")`,
    backgroundSize: "cover",
  });

  const sizeCalc = () => {
    const screenWidth = window.innerWidth

    if(screenWidth < 639) size = "xs"
    else if(screenWidth < 768) size =  "sm"
    else if(screenWidth < 1024) size =  "md"
    else size = "lg";
  }

  const resizeHandler = () => {
    sizeCalc()
    setImageSrc(`/banner/${page}_${size}.png`)
  }

  useEffect(()=>{
    sizeCalc()
    setImageSrc(`/banner/${page}_${size}.png`);

    window.addEventListener('resize', resizeHandler);

    return () => {window.removeEventListener('resize', resizeHandler);}
  }, [])

  return (
    <div className="banner text-white flex justify-center white-logo-section">
      <div className="banner__img" style={BannerImageStyle(imageSrc)}>
        <div className="banner__img-backdrop" />
      </div>

      <div className="banner__text container">
        <div className="banner__text-category font-bold">{category}</div>
        <div className="banner__text-menu font-bold">{menu}</div>
        <div className="banner__text-desc">{desc}</div>
      </div>
    </div>
  );
};

export default Banner;
