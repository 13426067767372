import { useNavigate } from "react-router-dom";
import "./GoList.scss";
import { Button } from "@mui/material";
import { ReactComponent as prevButton } from "../../assets/img/icon/arrow_prev.svg";

const GoList = ({ page }) => {
  const navigate = useNavigate();

  return (
    <div className="go-list">
      <div className="go-list-wrapper flex col-span-7 md:col-span-9">
        <Button
          startIcon={
            <span className="mn-icon go-list__icon">
                  <img className="icon-arrow-left" src={prevButton} alt="" />
            </span>
          }
          classes={{
            root: "go-list__btn",
          }}
          onClick={() => navigate(`/news/${page}`)}>
          목록으로
        </Button>
      </div>
    </div>
  );
};

export default GoList;
