import React from "react";
import Error from "../../pages/error/Error";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    // logErrorToMyService(error, errorInfo);
    console.error("Uncaught error:", error, errorInfo); //error 메세지 찍기
  }

  render() {
    const { hasError } = this.state;
    if (hasError) {
      return <Error props={this.state} />;
    }

    return this.props.children;
  }
}


export default ErrorBoundary;
