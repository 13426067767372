import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import Banner from "../../components/banner/Banner";
import searchHoverIcon from "../../assets/img/icon/search_hover.png";
import searchIcon from "../../assets/img/icon/search.png";
import "../../components/toolbar/Toolbar.scss";
import { postAPI } from "../../common/api/waoBackApi";
import "./index.scss";
import { SearchNews } from "./searchNews";
import { SearchGallery } from "./searchGallery";
import { SearchPost } from "./searchPost";
import Select from "react-select";

const Index = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [page, setPage] = useState(1);
  const [apiData, setApiData] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [totalCnt, setTotalCnt] = useState(0);
  const [params, setParams] = useState({
    type: searchParams.get("type") || "2",
    year: searchParams.get("year") || "",
    project: searchParams.get("project") || "",
    search: searchParams.get("search") || "",
  });
  const [serchText, setSearchText] = useState(searchParams.get("search") || "");
  const [searchIcon_yellow, setSearchIcon_yellow] = useState(false);
  const handlePage = (page) => setPage(page);

  const selectedComponent = (selected) => {
    if (selected) {
      const { value, count } = selected;
      const num = new Number(value);
      const temp = [
        ,
        ,
        <SearchPost
          data={apiData}
          count={count}
          searchText={params.search}
          handlePage={handlePage}
        />,
        <SearchNews
          data={apiData}
          count={count}
          searchText={params.search}
          handlePage={handlePage}
        />,
        <SearchGallery
          data={apiData}
          count={count}
          handlePage={handlePage}
          params={params}
        />,
      ];

      return temp[num];
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setSearchParams({ ...params, search: serchText });
  };
  const getApiData = () => {
    const param = {
      type: searchParams.get("type") || "2",
      year: searchParams.get("year") || "",
      project: searchParams.get("project") || "",
      search: searchParams.get("search") || "",
    };

    postAPI(
      `/common/search?page=${page}&year=${param.year}&project=${param.project}&search=${param.search}&type=${param.type}`
    )
      .then(({ data }) => {
        const { totalCnt, noticeCnt, postCnt, newsCnt, galleryCnt } = data.data;
        setTotalCnt(totalCnt);
        setApiData(data.data.list);
        setCategoryList([
          // { value: "1", title: "공지사항", count: noticeCnt },
          { value: "2", title: "포스트", count: postCnt }, //API때문에 value가 지정되어있음
          { value: "3", title: "언론보도", count: newsCnt },
          { value: "4", title: "갤러리", count: galleryCnt },
        ]);
        if (params.type === "4" && page !== 1 && data.data.list.length > 0) {
          const accumulatedList = apiData.concat(data.data.list);
          setApiData(accumulatedList);
        } else setApiData(data.data.list);
      })
      .catch((err) => {
        // logger.error(`/common/search api error : ${err}`);
      });
  };
  const selectType = (o) => {
    setSearchParams({ ...params, ...o });
    setPage(1);
  };
  const handleToolbarData = (o) => setSearchParams({ ...params, ...o });

  useEffect(() => {
    setParams({
      type: searchParams.get("type") || "2",
      year: searchParams.get("year") || "",
      project: searchParams.get("project") || "",
      search: searchParams.get("search") || "",
    });
    getApiData();
  }, [, page, searchParams]);
  useEffect(() => {
    setSearchText(searchParams.get("search") || "");
  }, [searchParams]);
  const handleSearchText = (e) => {
    setSearchText(e.target.value);
  };
  const yearOptions = [
    { value: "", label: "전체" },
    { value: "2022", label: "2022" },
    { value: "2023", label: "2023" },
  ];

  const projectOptions = [
    { value: "", label: "전체 사업" },
    { value: "1", label: `위아"런"` },
    { value: "2", label: `위아레드` },
    { value: "0", label: "그 외" },
  ];
  return (
    <div className="search">
      <Banner
        category="WE ARE ONE"
        menu={`" ${params.search} " 에 대한 검색 결과입니다`}
        desc={`총 ${totalCnt} 건`}
        page="search"
      />

      <div className="search-container container mx-auto">
        <div className="toolbar grid grid-cols-12 gap-4">
          <div className="toolbar__filter flex col-span-7 md:col-span-9">
            <Select
              className="toolbar__filter-select"
              classNamePrefix="toolbar__filter-select"
              placeholder="연도 선택"
              options={yearOptions}
              defaultValue={() => {
                const one = yearOptions.find(
                  (option) => option.value == searchParams.get("year")
                );
                return one;
              }}
              isSearchable={false}
              onChange={({ value }) => handleToolbarData({ year: value })}
            />
            <Select
              className="toolbar__filter-select"
              classNamePrefix="toolbar__filter-select"
              placeholder="사업 선택"
              options={projectOptions}
              defaultValue={() => {
                const one = projectOptions.find(
                  (option) => option.value == searchParams.get("project")
                );
                return one;
              }}
              isSearchable={false}
              onChange={({ value }) => handleToolbarData({ project: value })}
            />
          </div>
          <div className="toolbar__search col-span-5 md:col-span-3">
            <form onSubmit={handleSubmit}>
              <input
                id="search"
                type="text"
                value={serchText}
                onChange={(e) => handleSearchText(e)}
              />
              <button
                className="ml-2"
                type="submit"
                onMouseOver={() => setSearchIcon_yellow(true)}
                onMouseOut={() => setSearchIcon_yellow(false)}>
                {serchText.length > 0 || searchIcon_yellow ? (
                  <img src={searchHoverIcon} />
                ) : (
                  <img src={searchIcon} />
                )}
              </button>
            </form>
          </div>
        </div>

        <div className="search__header">
          <div className="search__header-total font-bold col-span-12">
            "{params.search}"에 대한 검색 결과 총 {totalCnt} 건
          </div>
          <div className="search__header-category-cover">
            <div className="search__header-category grid grid-cols-3">
              {categoryList.map((item) => {
                return (
                  <button
                    className={item.value === params.type ? "selected" : ""}
                    key={item.title}
                    onClick={() => {
                      selectType({ type: item.value });
                    }}>
                    <div className="search__header-category-title">
                      {item.title}
                    </div>
                    <div className="search__header-category-total">
                      ({item.count})
                    </div>
                  </button>
                );
              })}
            </div>
          </div>
        </div>

        <div className="search__body">
          {selectedComponent(categoryList.find((i) => i.value == params.type))}
        </div>
      </div>
    </div>
  );
};

export default Index;
