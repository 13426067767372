import Routers from "./router";
import { useEffect } from "react";
import ErrorBoundary from "./common/utils/ErrorBoundary";
import Layout from "./components/layout";
import "animate.css";
import "./assets/css/common.css";
import "./assets/css/style.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Helmet } from "react-helmet-async";

export default function App() {
  useEffect(() => {
    window.Kakao.init(process.env.REACT_APP_KAKAO_KEY);
  }, []);

  return (
    <div className="App">
      <ErrorBoundary>
        <Layout>
          <Routers />
        </Layout>
      </ErrorBoundary>
    </div>
  );
}
