import { useState } from "react";
import Paging, { usePagination } from "../../components/paging/Paging";
import ListTemplate from "../../components/template/ListTemplate";

export const SearchNews = ({ data, count, searchText, handlePage }) => {
  const [viewItem] = useState(10);
  const [page, setPage] = useState(1);
  const pageCount = Math.ceil(count / viewItem);
  const _searchList = usePagination(data, viewItem);

  const handleChange = (e, p) => {
    handlePage(p);
    setPage(p);
    _searchList.jump(p);
    window.scrollTo(0, 0);
  };
  return (
    <div>
      {_searchList.currentData()?.map((item) => (
        <ListTemplate
          key={item.no}
          id={item.no}
          menu={"언론보도"}
          title={item.title}
          content={item.subTitle}
          tags={item.tags}
          date={item.created_at}
          page={"press"}
          wordLimitLength={100}
          searchText={searchText}
          thumbnail={item.image}
        />
      ))}

      <div className="search__footer flex justify-center">
        {data.length > 0 ? (
          <Paging
            page={page}
            count={pageCount}
            handleChange={(e, p) => handleChange(e, p)}
          />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};
