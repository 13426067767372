


const aosSetting = {
  offset: 100,
  duration: 300,
  easing: "linear",
  delay: 100,
};

export { aosSetting };
