export const FAMILYSITE = [
    { name: "신천지 공식 홈페이지", link: "https://www.shincheonji.org/" },
    { name: "신천지 자원봉사단", link: "http://www.scjvolunteers.com/" },
    { name: "인터넷시온선교센터", link: "https://www.eduzion.org/" },
    { name: "말씀광장", link: "https://www.wordsquare.org/" },
];

export const SNS_LINK = {
    instagram: "https://www.instagram.com/weareone_youth/",
    youtube: "https://bit.ly/3CUhKaf",
};