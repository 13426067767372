function menuOpen(state, action) {
  if (action.type === "menuOpen") {
    state = true;
    return state;
  }
  if (action.type === "menuClose") {
    state = false;
    return state;
  } else return false;
}

export default menuOpen;
