import { withCookies } from "react-cookie";
import { Route, Routes } from "react-router-dom";
import Index from "../pages/search";
import Error from "../pages/error/Error";
const ProjectRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<Index />} />
      <Route path="/*" element={<Error />} />
    </Routes>
  );
};
export default withCookies(ProjectRouter);
