import { withCookies } from "react-cookie";
import { Route, Routes } from "react-router-dom";
import Gallery from "../pages/gallery";
import Error from "../pages/error/Error";
const GalleryRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<Gallery />} />
      <Route path="/*" element={<Error />} />
    </Routes>
  );
};
export default withCookies(GalleryRouter);
