import React, { useState } from "react";
import searchIcon from "../../assets/img/icon/search.png";
import searchHoverIcon from "../../assets/img/icon/search_hover.png";
import Select, { type DropdownIndicatorProps, components } from "react-select";
import "./Toolbar.scss";
import { useDispatch } from "react-redux";
import arrowDown from "../../assets/img/icon/arrow_down.svg";
import Alert from "../alert/Alert";

const Toolbar = ({ handleToolbarData }) => {
  const [serchText, setSearchText] = useState("");
  const [searchIcon_yellow, setSearchIcon_yellow] = useState(false);
  const [open, setOpen] = useState(false);
  const yearOptions = [
    { value: "", label: "전체" },
    { value: "2022", label: "2022" },
    { value: "2023", label: "2023" },
  ];

  const projectOptions = [
    { value: "", label: "전체 사업" },
    { value: "1", label: `위아"런"` },
    { value: "2", label: `위아레드` },
    { value: "0", label: "그 외" },
  ];

  const dispatch = useDispatch();

  const DropdownIndicator: React.FC<DropdownIndicatorProps> = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <img src={arrowDown} />
      </components.DropdownIndicator>
    );
  };

  const handleSearchText = (e) => {
    setSearchText(e.target.value);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (serchText) {
      dispatch({ type: "SEARCH", payload: { text: serchText } });
      handleToolbarData({ search: serchText });
    } else handleClickOpen();
  };

  // alert handling
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className="toolbar grid grid-cols-12 gap-4">
      <div className="toolbar__filter flex col-span-7 md:col-span-9">
        <Select
          className="toolbar__filter-select"
          classNamePrefix="toolbar__filter-select"
          placeholder="연도 선택"
          components={{ DropdownIndicator }}
          options={yearOptions}
          isSearchable={false}
          onChange={({ value }) => handleToolbarData({ year: value })}
        />
        <Select
          className="toolbar__filter-select"
          classNamePrefix="toolbar__filter-select"
          placeholder="사업 선택"
          components={{ DropdownIndicator }}
          options={projectOptions}
          isSearchable={false}
          onChange={({ value }) => handleToolbarData({ project: value })}
        />
      </div>

      <div className="toolbar__search col-span-5 md:col-span-3">
        <form onSubmit={handleSubmit}>
          <input
            id="search"
            type="text"
            onChange={(e) => handleSearchText(e)}
            value={serchText}
          />
          <button
            className="ml-2"
            type="submit"
            onMouseOver={() => setSearchIcon_yellow(true)}
            onMouseOut={() => setSearchIcon_yellow(false)}>
            {serchText.length > 0 || searchIcon_yellow ? (
              <img src={searchHoverIcon} />
            ) : (
              <img src={searchIcon} />
            )}
          </button>
        </form>
      </div>

      <Alert open={open} handleClose={handleClose} />
    </div>
  );
};

export default Toolbar;
