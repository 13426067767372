import "./PostTemplate.scss";
import { useNavigate } from "react-router-dom";
import { shortenAndHighlightingSentence } from "./utils";

const PostTemplate = ({
  id,
  title,
  content,
  tags,
  date,
  src,
  wordLimitLength = 100,
  searchText,
}) => {
  const navigate = useNavigate();

  return (
    <div className="post-template">
      <div
        className="post-template__img-wrapper"
        onClick={() => navigate(`/news/post/${id}`)}>
        <img className="post-template__img" src={src} alt={title} />
      </div>

      <div
        className="post-template__text-title font-bold"
        onClick={() => navigate(`/news/post/${id}`)}>
        {shortenAndHighlightingSentence(title, searchText)}
      </div>
      <div className="post-template__text-content">
        {shortenAndHighlightingSentence(content, searchText, wordLimitLength)}
      </div>
      <div className="post-template__text-tags">
        {tags?.map(({ tag_name }, index) => (
          <span key={index}>
            #{shortenAndHighlightingSentence(tag_name, searchText)}
          </span>
        ))}
      </div>
      <div className="post-template__text-date">{date}</div>
    </div>
  );
};

export default PostTemplate;
