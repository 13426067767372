import { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router";
import weAreOneText from "../../assets/img/main/hero_text_one.svg";
import weAreRedText from "../../assets/img/main/hero_text_red.svg";
import weAreRunText from "../../assets/img/main/hero_text_run.svg";
import heroCircle from "../../assets/img/main/hero_circle.png";
import aboutLogo from "../../assets/img/main/about_logo.png";
import aboutNumberOne from "../../assets/img/main/about_number_one.png";
import aboutStar from "../../assets/img/main/about_star2.png";
import topicCircle from "../../assets/img/main/topic_circle_bg.png";
import topicText from "../../assets/img/main/topic_circle_text.png";
import Carousel from "react-bootstrap/Carousel";
import "./index.scss";

const Main = () => {
  const navigate = useNavigate();
  const pageMove = (url) => navigate(url);

  const [xPos, setXPos] = useState(0);
  const [yPos, setYPos] = useState(0);
  const [topicContainerMaxOver, setTopicContainerMaxOver] = useState(true);
  const [topicOpacity, setTopicOpacity] = useState([0, 0, 0]);
  const containerRef = useRef(null);
  const heroDelaySeconds = 1000;
  const heroProjectImageExpandRatio = 2.6;

  // image list
  const heroItemList = [
    {
      lg: `/main/hero_one_lg.png`,
      sm1: `/main/hero_one_sm1.png`,
      sm2: `/main/hero_one_sm2.png`,
      text: weAreOneText,
    },
    {
      lg: `/main/hero_red_lg.png`,
      sm1: `/main/hero_red_sm1.png`,
      sm2: `/main/hero_red_sm2.png`,
      text: weAreRedText,
    },
    {
      lg: `/main/hero_run_lg.png`,
      sm1: `/main/hero_run_sm1.png`,
      sm2: `/main/hero_run_sm2.png`,
      text: weAreRunText,
    },
  ];

  const projectImages = [
    { src: `/main/project_img1.png` },
    { src: `/main/project_img2.png` },
    { src: `/main/project_img3.png` },
    { src: `/main/project_img4.png` },
  ];

  const topicItemList = [
    {
      no: "01",
      title: "POST",
      opacity: 0,
      src: require("../../assets/img/main/topic_hover_img2.png"),
      path: "/news/post",
    },
    {
      no: "02",
      title: "PRESS",
      opacity: 0,
      src: require("../../assets/img/main/topic_hover_img1.png"),
      path: "/news/press",
    },
    {
      no: "03",
      title: "GALLERY",
      opacity: 0,
      src: require("../../assets/img/main/topic_hover_img3.png"),
      path: "/gallery",
    },
  ];

  const galleryItemList = [
    { type: "image", src: `/main/gallery_img1.png` },
    {
      type: "text",
      text: "하나님과 맺은 언약을 생각과 마음에 새기고 예수님께 받은 생명과 은혜를 마땅히 보답하겠습니다.",
    },
    { type: "blank" },
    { type: "image", src: `/main/gallery_img2.png` },
    {
      type: "text",
      text: "국가적 혈액 수급이 위급한 시기에 건강한 위아원 청년들이 생명을 살리는 가치있는 봉사를 통하여 선한 영향력을 전파하고자 합니다.",
    },
    { type: "image", src: `/main/gallery_img3.png` },
    { type: "blank" },
    { type: "image", src: `/main/gallery_img4.png` },
    {
      type: "text",
      text: "위아원 국내 자원봉사자 7만1121명이 24시간 헌혈자 등록을 완료해 기네스 세계기록 달성하였습니다.",
    },
    { type: "blank" },
    { type: "image", src: `/main/gallery_img5.png` },
    {
      type: "text",
      text: "대한민국 청년들을 격려하고 함께 뛰기 위하여 전국 단위의 러닝 크루를 운영, 러닝 행사를 통해 마련되는 기부금으로 소외되고 멈춰있는 많은 사람을 돕고자 합니다.",
    },
  ];

  const aboutScrollAnimation = () => {
    const aboutSection = document.querySelector(".main-about");
    const oneImg = document.querySelector(".one_fade-in");
    const starImg = document.querySelector(".star-fade-in-cover img");
    const aboutPosition = aboutSection?.getBoundingClientRect();
    const offset = 300;
    if (aboutPosition?.top < offset && aboutPosition?.bottom > -offset) {

      oneImg.classList.add("oneFadeIn");
      // eslint-disable-next-line no-unused-expressions
      starImg.offsetWidth;
      starImg.classList.add("starFadeIn");
    } else {
      oneImg.classList.remove("oneFadeIn");
      starImg.classList.remove("starFadeIn");
    }
  };

  const onTopicMouseHover = (index) => {
    let temp = Array(3).fill(0);
    temp[index] = 1;
    setTopicOpacity(temp);
  };
  const onTopicMouseHoverReset = (e) => {
    setTopicOpacity([0, 0, 0]);
  };

  const expandImageWidth = (idx) => {
    const imageGallery = document.querySelector(
      ".main-project__image-container"
    );
    let sizeArray = [1, 1, 1, 1];
    sizeArray[idx] *= heroProjectImageExpandRatio;
    imageGallery.style.gridTemplateColumns = `${sizeArray[0]}fr ${sizeArray[1]}fr ${sizeArray[2]}fr ${sizeArray[3]}fr`;
  };

  const contractImageWidth = () => {
    const imageGallery = document.querySelector(
      ".main-project__image-container"
    );
    imageGallery.style.gridTemplateColumns = "1fr 1fr 1fr 1fr";
  };

  useEffect(() => {
    const container = containerRef.current;

    const heroes = document.querySelector(".main-heroes");
    heroes.style.opacity = 0;

    setTimeout(() => {
      heroes.style.opacity = 1;
    }, heroDelaySeconds);

    function handleMouseMove(e) {
      const rect = container.getBoundingClientRect();
      const imageDom = document.querySelector(".main-topic__hover_image");
      const listItem = document.querySelectorAll(".main-topic__list-item");
      const listArea = listItem[2].getBoundingClientRect();

      setXPos(e.clientX - rect.left);
      setYPos(e.clientY - imageDom.height / 2);

      // TODO: 영역 밖으로 나가면, 그냥 opacity 0으로 만들도록 구현해둠.
      ((e.clientX > listArea.right) || (e.clientY > listArea.bottom))
          ? setTopicContainerMaxOver(true)
          : setTopicContainerMaxOver(false)
    }

    container.addEventListener("mousemove", handleMouseMove);

    return () => {
      container.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", aboutScrollAnimation, true);

    return () => {
      window.removeEventListener("scroll", aboutScrollAnimation, true);
    };
  });

  return (
    <div className="main">
      <section className="main-heroes">
        <Carousel controls={false} slide={false}>
          {heroItemList.map((item, idx) => {
            return (
              <Carousel.Item key={idx}>
                {idx % 2 === 0 ? (
                  <div className="main-heroes-container">
                    <div className="main-heroes__image--sm1">
                      <div className="">
                        <img src={item.sm1} alt="" />
                      </div>
                    </div>

                    <div className="main-heroes__image--sm2">
                      <div className="">
                        <img src={item.sm2} alt="" />
                      </div>
                    </div>

                    <div className="main-heroes__image--lg">
                      <img src={item.lg} alt="" />
                    </div>

                    <div className="main-heroes__image--text">
                      <div className="main-heroes__image--text-container">
                        <img
                          src={heroCircle}
                          className="main-heroes__image--text--logo"
                          alt=""
                        />
                        <img
                          className="main-heroes__image--text--ment"
                          src={item.text}
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="main-heroes-container even white-logo-section">
                    <div className="main-heroes__image--lg">
                      <img src={item.lg} alt="" />
                    </div>
                    <div className="main-heroes__image--sm2">
                      <div className="">
                        <img src={item.sm2} alt="" />
                      </div>
                    </div>
                    <div className="main-heroes__image--sm1">
                      <div className="">
                        <img src={item.sm1} alt="" />
                      </div>
                    </div>

                    <div className="main-heroes__image--text">
                      <div className="main-heroes__image--text-container">
                        <img
                          src={heroCircle}
                          className="main-heroes__image--text--logo"
                          alt=""
                        />
                        <img
                          className="main-heroes__image--text--ment"
                          src={item.text}
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                )}
              </Carousel.Item>
            );
          })}
        </Carousel>
      </section>

      <section className="main-about">
        <h2 className="main__title"> ABOUT </h2>
        <p className="main__text">
          <strong>WE ARE ONE</strong> 은 성경을 기반으로 진리, 봉사, 사랑을
          실천하는 <strong>청년 자원봉사단체</strong>입니다.
        </p>

        <div className="main-about__image-wrapper">
          <img className="main-about__image" src={aboutLogo} alt="" />
          <img
            className="main-about__image one_fade-in oneFadeIn"
            src={aboutNumberOne}
            alt=""
          />
          <div className="star-fade-in-cover">
            <img
              className="main-about__image starFadeIn"
              src={aboutStar}
              alt=""
            />
          </div>
        </div>

        <p className="main__text">
          국경,인종,정치,종교,이념을 초월하여 전 세계 청년의 자유를 수호하고,
          <br />
          나아가 인간 존엄성의 가치를 실현합니다.
        </p>

        <div className="main__actions">
          <button onClick={() => pageMove("/about")}>더 알아보기</button>
        </div>
      </section>

      <section className="main-project">
        <h2 className="main__title"> PROJECT</h2>
        <p className="main__text">
          위아원은 세상을 소성하기 위해 다양한 사업을 진행하고 있습니다.
        </p>

        <div className="main-project__image-container white-logo-section">
          {projectImages.map((p, idx) => {
            return (
              <div
                key={idx}
                className="main-project__image-wrapper"
                onMouseOver={() => {
                  expandImageWidth(idx);
                }}
                onMouseOut={() => {
                  contractImageWidth();
                }}>
                <img className="main-project__image" src={p.src} alt="" />
              </div>
            );
          })}
        </div>

        <div className="main__actions">
          <button onClick={() => pageMove("/project")}>더 알아보기</button>
        </div>
      </section>

      <section className="main-topic">
        <h2 className="main__title">TOPIC</h2>
        <div className="main-topic-container">
          <div className="main-topic__image--logo">
            <img
              className="main-topic__image--logo--bg"
              src={topicCircle}
              alt=""
            />
            <img
              className="main-topic__image--logo--outline"
              src={topicText}
              alt=""
            />
          </div>

          <ul
            className="main-topic__list"
            ref={containerRef}
            onMouseLeave={(e) => {
              setTopicOpacity([0, 0, 0]);
            }}>
            {topicItemList.map((item, index) => (
              <li
                key={index}
                className="main-topic__list-item"
                onMouseOver={(e) => {
                  onTopicMouseHover(index);
                }}
                onScroll={(e) => {
                  onTopicMouseHoverReset(e);
                }}>
                <div
                  className="main-topic__list-item__text-container"
                  onClick={() => pageMove(item.path)}>
                  <div
                    className="main-topic__list-item__text-cover"
                    style={{
                      zIndex: topicOpacity[index] ? 1000 : 0,
                    }}>
                    <span className="index">{item.no}</span>
                    <span className="title">{item.title}</span>
                  </div>
                </div>

                <div
                  className="main-topic__hover_image-container"
                  style={{
                    left: xPos + "px",
                    top: yPos + "px",
                    opacity: topicContainerMaxOver ?0 :topicOpacity[index],
                  }}>
                  <div className="main-topic__hover_image-cover">
                    <img
                      src={item.src}
                      alt=""
                      className="main-topic__hover_image"
                    />
                  </div>
                </div>

                <div
                  className="main-topic__title_effect"
                  style={{ opacity: topicOpacity[index] }}>
                  {item.title}
                </div>
              </li>
            ))}
          </ul>
        </div>
      </section>

      <section className="main-gallery">
        <div className="main-gallery-container">
          {galleryItemList.map((item, idx) => {
            return (
              <div className="main-gallery__item" key={idx}>
                {item.type === "image" ? (
                  <img src={item.src} alt="" />
                ) : item.type === "text" ? (
                  <div className="main-gallery__item__text">{item.text}</div>
                ) : (
                  <></>
                )}
              </div>
            );
          })}
        </div>
      </section>
    </div>
  );
};
export default Main;
