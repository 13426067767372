import { Fragment, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
import MenuArr from "./menuArr.json";
import { ReactComponent as SearchIcon } from "../../assets/img/icon/search.svg";
import { ReactComponent as CloseIcon } from "../../assets/img/icon/close.svg";
import "./Menu.scss";
import Alert from "../alert/Alert";

const Menu = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isMenuOpen = useSelector((state) => state.menuOpen);
  const [search, setSearch] = useState("");
  const [hoverMouse, setHoverMouse] = useState({
    news: false,
  });
  const { pathname } = location;
  const [open, setOpen] = useState(false);

  // Menu open, close 상태관리
  const handleMenuOpen = () => {
    isMenuOpen
      ? dispatch({ type: "menuClose" })
      : dispatch({ type: "menuOpen" });
    setSearch("");
    searchingInitState();
  };

  // menu 눌렀을 때, 해당 페이지로 이동
  const pageMove = (url) => navigate(url);

  // 메뉴 클릭
  const clickMenu = (url) => {
    if (url == pathname) window.location.reload();

    pageMove(url);
    dispatch({ type: "menuClose" });
  };

  // 검색어 입력시 change
  const onChangeSearch = (e) => {
    e.preventDefault();
    setSearch(e.target.value);
    searchingInputState();
  };

  // enter 눌러도 search 창 갈 수 있도록
  const handleOnKeyUp = (e) => {
    if (e.target.value && e.key === "Enter") {
      pageMove(`/search?type=2&year=&project=&search=${search}`);
      handleMenuOpen();
      setHoverMouse(true);
      dispatch({ type: "SEARCH", payload: { text: e.target.value } });
    }
  };

  // 뉴스룸 열었을 때, hover 상태 유지
  const handleHoverState = (path) => {
    const isSubMenuOpen = hoverMouse[path];
    setHoverMouse((p) => ({ ...p, [path]: !isSubMenuOpen }));
  };

  // 뉴스룸에서 뉴스룸이나 그 안에 서브메뉴를 보고 있을 때, selected class 추가해주는 기능.
  const isSelected = (mainUrl, subUrl) => {
    const mainPath = mainUrl.split("/*")[0];
    const subPath = subUrl.split("/*")[0];
    return (
      (subUrl &&
        pathname.split("/").includes(mainPath) &&
        pathname.split("/").includes(subPath)) ||
      (!subUrl && pathname === mainPath)
    );
  };

  // subMenu 열고 있을건지, news 가 아니면 닫아라.
  const initSubMenuOpen = () => {
    setHoverMouse({ news: false });
  };

  // 다른 영역 클릭시 닫기
  window.addEventListener("mouseup", function (e) {
    let container = document.querySelector(".menu");
    if (isMenuOpen && !container.contains(e.target)) {
      // container.classList.add("hide");
      handleMenuOpen();
    }
  });

  const searchingInputState = () => {
    const searchIcon = document.querySelector(
      ".menu__icon-search .search_icon"
    );
    const searchPath = searchIcon.querySelector("path");
    const searchCircle = searchIcon.querySelector("circle");
    if (search) {
      searchPath.setAttribute("stroke", "#FFAD10");
      searchCircle.setAttribute("stroke", "#FFAD10");
    }
  };

  const searchingInitState = () => {
    const searchIcon = document.querySelector(
      ".menu__icon-search .search_icon"
    );
    const searchPath = searchIcon.querySelector("path");
    const searchCircle = searchIcon.querySelector("circle");

    searchPath.setAttribute("stroke", "#F8F8F8");
    searchCircle.setAttribute("stroke", "#F8F8F8");
  };

  // alert handling
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className={"menu " + (isMenuOpen ? "" : "hide")}>
      <div className="menu__icon-group animate__animated animate__fadeIn">
        <div className="menu__icon-search">
          <input
            value={search}
            onChange={onChangeSearch}
            onKeyUp={handleOnKeyUp}
          />
          <button
            onClick={(e) => {
              if (search) {
                pageMove(`/search?type=2&year=&project=&search=${search}`);
                handleMenuOpen();
                setHoverMouse(true);
                dispatch({ type: "SEARCH", payload: { text: e.target.value } });
              } else handleClickOpen();
            }}>
            <SearchIcon stroke="#FFAD10" />
          </button>
        </div>

        <div className="menu__icon-close">
          <button onClick={handleMenuOpen}>
            <CloseIcon />
          </button>
        </div>
      </div>

      <ul className="menu__item-group">
        {MenuArr.map((menuItem) => (
          <Fragment key={menuItem.path}>
            <li className={`menu__item ${menuItem.path}`}>
              <a
                className={clsx("menu__item-link", {
                  selected: pathname.split("/")[1] === menuItem.path,
                })}
                onClick={() => {
                  if (menuItem.children.length === 0) {
                    clickMenu(`/${menuItem.path}`);
                    initSubMenuOpen();
                  } else handleHoverState(menuItem.path);
                }}>
                <span>{menuItem.title}</span>
              </a>

              {menuItem.children.length > 0 && hoverMouse[menuItem.path] ? (
                <ul className="menu__item-nested animate__animated animate__fadeInDown">
                  {menuItem.children.map((subMenu) => (
                    <li key={subMenu.menuId}>
                      <a
                        className={clsx("menu__item-link", {
                          selected: isSelected(menuItem.path, subMenu.path),
                        })}
                        onClick={() => {
                          clickMenu(`/${menuItem.path}/${subMenu.path}`);
                        }}>
                        <span> {subMenu.title}</span>
                      </a>
                    </li>
                  ))}
                </ul>
              ) : (
                ""
              )}
            </li>
          </Fragment>
        ))}
      </ul>

      <Alert open={open} handleClose={handleClose} />
    </div>
  );
};

export default Menu;
