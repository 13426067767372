import axios from "axios";

export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

//post방식
export const postAPI = async (url, body = []) => {
  return await axiosInstance.post(url, body);
};

//delete 방식
export const deleteAPI = async (url, body) => {
  return await axiosInstance.delete(url, body);
};

//patch 방식
export const patchAPI = async (url, body) => {
  return await axiosInstance.patch(url, body);
};

//put 방식
export const putAPI = async (url, body) => {
  return await axiosInstance.put(url, body);
};

//파일 업로드
export const fileUploadtAPI = async (url, params) => {
  return await axiosInstance.post(url, params, {
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
  });
};

//파일 다운로드
export const fileDownloadAPI = async (url) => {
  return await axiosInstance.get(url, { responseType: "blob" });
};
