import { useState } from "react";
import { Pagination } from "@mui/material";
import "./Paging.scss";

export const usePagination = (data, itemsPerPage) => {
  const [currentPage, setCurrentPage] = useState(1);
  const maxPage = Math.ceil(data.length / itemsPerPage);

  function currentData() {
    const begin = (currentPage - 1) * itemsPerPage;
    const end = begin + itemsPerPage;
    return data.slice(begin, end);
  }

  function next() {
    setCurrentPage((currentPage) => Math.min(currentPage + 1, maxPage));
  }

  function prev() {
    setCurrentPage((currentPage) => Math.max(currentPage - 1, 1));
  }

  function jump(page) {
    const pageNumber = Math.max(1, page);
    setCurrentPage(() => Math.min(pageNumber, maxPage));
  }

  return { next, prev, jump, currentData, currentPage, maxPage };
};

export default function Paging({ page, count, handleChange }) {
  return (
    <Pagination
      shape="rounded"
      page={page}
      count={count}
      onChange={(e, p) => handleChange(e, p)}
      classes={{
        root: "pagination",
        ul: "pagination-ul",
      }}
    />
  );
}
