//Help desk Dashboard
import { useNavigate } from "react-router-dom";

import "./index.scss";

export default function Error(message) {
  // logger.error(`Page Err : ${message}`);
  const navigate = useNavigate();
  return (
    <div className="error text-white flex justify-center white-logo-section">
      <div
        className="error__img"
        style={{
          backgroundImage: `url("/banner/error_lg.png")`,
          backgroundSize: "cover",
        }}>
        <div className="error__img-backdrop" />
      </div>
      <div className="error__text-container">
        <div className="error__text-title font-bold">
          <span>
            죄송합니다.
            <br /> 원하시는 페이지를 찾을 수 없습니다.
          </span>
        </div>
        <div className="error__text-desc">
          <span>
            찾으시는 페이지의 주소가 잘못 입력되었거나,
            <br />
            서버에 문제가 발생하여 접근하실 수 없습니다.
            <br />
            홈으로 돌아가시거나 다른 페이지로 이동하여 원하는 정보를 찾아보세요.
          </span>
        </div>
        <div className="error__back">
          <button onClick={() => navigate(-1)}>이전 페이지로 돌아가기</button>
        </div>
      </div>
    </div>
  );
}
