import './Alert.scss'
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";

import { ReactComponent as AlertIcon } from "../../assets/img/icon/alertIcon.svg";

const Alert = ({ text, open, handleClose }) => {
    return (
        <div>
            <Dialog
                id="alert-dialog"
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth={true}
                maxWidth={"xs"}
            >
                <DialogTitle id="alert-dialog-title">
                </DialogTitle>
                <DialogContent>
                    <AlertIcon />

                    <DialogContentText id="alert-dialog-description">
                        {(text) ?text :'검색어를 입력해주세요.'}
                    </DialogContentText>
                </DialogContent>
                <DialogActions id="alert-dialog-actions">
                    <Button id="alert-dialog-button" onClick={handleClose} size="small" autoFocus>
                        확인
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default Alert
